import React from 'react';
import { useCart } from '../../../hooks/useCart'
import CartItemList from '../cart/cartItemList'
import ReedmakerPayment from '../paypal/ReedmakerPayment'
import ReedmakerConfig from "../../../../content/reedmaker.yaml"

const formatValue = value => "$" + value.toFixed(2)

function Checkout() {
    const { isEmpty, isShippingSet } = useCart();
  
    if (isEmpty) 
        return <EmptyCart />
    else if (!isShippingSet())
        return <Shipping />
    else
        return <Cart />
}

function EmptyCart() {
    return (
        <React.Fragment>
            <p>
                Your cart is empty. Please go checkout some products before you checkout.
            </p>
        </React.Fragment>
    )
}

function Cart() {
  const { cartTotal, shippingTotal } = useCart();
  const orderTotal = cartTotal + shippingTotal;

  return (
    <React.Fragment>
        <div className="lg:flex -mx-4">
            <div className="lg:w-1/2 lg:w-2/5 px-4 order-last">
                <div className="lg:sticky lg:top-0" style={{top:100}}>
                    <div className="rounded bg-white border-2 border-gray-200 p-3 md:p-6 my-3 md:my-6 my-8">
                        <h3 className="font-bold text-xl mb-3 text-primary">Your Cart</h3>
                        <CartItemList freezeItems={true} />
                        
                        <div className="flex items-center justify-between">
                            <span className="text-sm text-slategray">Sub total</span>
                            <span className="font-semibold">{formatValue(cartTotal)}</span>
                        </div>

                        <div className="flex items-center justify-between">
                            <span className="text-sm text-slategray">Shipping</span>
                            <span className="font-semibold">
                                {shippingTotal === 0 ? 'free' : formatValue(shippingTotal)}
                            </span>
                        </div>

                        <div className="flex items-center justify-between">
                            <span className="text-sm text-slategray">Tax (Calculated at checkout)</span>
                            <span className="">-</span>
                        </div>
 
                        <hr className="border-b border-gainsboro my-3 w-20" />
                        <div className="flex items-center justify-between">
                            <span className="text-lg font-semibold text-slategray">Total</span>
                            <span className="text-xl font-bold text-primary">
                            {formatValue(orderTotal)}
                            </span>
                        </div>

                    </div>
                </div>
            </div>
            <div className="lg:w-1/2 lg:w-3/5 px-4 order-first">
                <div className="p-3 md:p-6 my-3 md:my-6">
                    <Shipping />
                    <ReedmakerPayment/>
                </div>
            </div>
        </div>


      
    </React.Fragment>
  );
}

function Shipping() {
    const { addShipping, shipping, hasOboeItem, hasBassoonItem } = useCart();
    const { international, ground, priority } = ReedmakerConfig.content.shipping

    const updateShipping = (id) => {
        if (ReedmakerConfig.content.shipping[id]) {
            addShipping({ 
                id: id, 
                price: getShippingPrice(ReedmakerConfig.content.shipping[id]),
            })
        }
    }

    // Double the shipping if both bassoon and oboe items
    const getShippingPrice = (price) => {
        return price * ( hasBassoonItem && hasOboeItem ? 2 : 1)
    }

    return (
        <React.Fragment>
            <div className="my-6">
                <h3 className="font-bold text-xl mb-3 text-primary">Shipping Method</h3>
                <p>
                    Please Choose your shipping method. 
                    { (hasBassoonItem && hasOboeItem) && <span>Please note there is separate shipping from the oboe reedmaker and the bassoon reedmaker.</span> }
                </p>

                <div className="relative">
                    <select
                        value={shipping ? shipping.id : ''}
                        className="block appearance-none w-full bg-products border-2 border-gainsboro focus:border-slategray px-4 py-3 pr-8 focus:outline-none focus:bg-white text-slategray focus:text-slategray rounded-lg"
                        onChange={e => { updateShipping(e.currentTarget.value) }}
                    >
                        <option value="">
                            Select Shipping
                        </option>
                        <option value="ground">
                            Ground - {formatValue(getShippingPrice(ground))} USD
                        </option>
                        <option value="priority">
                            Priority - {formatValue(getShippingPrice(priority))} USD
                        </option>
                        <option value="international">
                            International Economy - {formatValue(getShippingPrice(international))} USD 2-3 Weeks
                        </option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-slategray">
                        <svg
                            className="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Checkout;
