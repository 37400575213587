import React from 'react';
import { useCart } from '../../../hooks/useCart';

import CartItem from './cartItem';

function CartItemList({ freezeItems = false }) {
  const { items } = useCart();

  return <div className="py-3">
    {items.map(item => 
      <CartItem key={item.id} freezeItems={freezeItems} {...item} />
    )}
  </div>;
}

export default CartItemList;
