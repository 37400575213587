import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Checkout from '../components/store/checkout/checkout'

const CheckoutPage = () => (
  <Layout>
    <div className="container mx-auto p-6 py-16">
        <div className="flex flex-wrap">
            <SEO title="cart" />

            <section className={`w-full p-3`}>                  
                <h1 className="font-bold text-3xl md:text-6xl mb-3 text-primary">
                    Checkout
                </h1>
                <div id="main-content-block">
                    <Checkout />
                </div>
            </section>

        </div>
    </div>
  </Layout>
)

export default CheckoutPage
