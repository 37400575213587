import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const ProductImage = ({ image }) => {
    if (!image) return <ProductImageDefault />
    const { imageFile, title, altText } = image
  
    
    return (
      <div className="">
        <Img 
          fluid={imageFile.childImageSharp.fluid}
          alt={altText}
          title={title}
        />
      </div>
    )
  }

const ProductImageDefault = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Reedmaker-Oboe-Reed.png" }) {
        childImageSharp {
          fluid(maxHeight: 400, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

export { 
    ProductImage,
}