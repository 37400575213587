import React from 'react';
import { navigate } from "gatsby"
import { useCart } from '../../../hooks/useCart';
import { PayPalButton } from './PaypalButton'
import ReedmakerConfig from "../../../../content/reedmaker.yaml"

const INTERNATION_SHIPPING_PRICE = ReedmakerConfig.content.shipping.international
const FREE_SHIPING_PRICE = ReedmakerConfig.content.shipping.freePrice
const CANADA_DUTY_FEE = 9.95

const ReedmakerPayment = () => {
    const { isEmpty, cartTotal, shippingTotal, items, emptyCart } = useCart();

    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                getPurchaseUnits()
            ],
            application_context: {
                shipping_preference: 'GET_FROM_FILE'
            }
        });
    }

    const onShippingChange = (data, actions) => {

        // DO NOT ALLOW these Countries
        if (data.shipping_address.country_code === 'GB') {
            return actions.reject();
        }

        // Add international Shipping if its not set
        if (data.shipping_address.country_code !== 'US' && data.shipping_address.country_code !== 'CA') {
            const purchaseUnits = getPurchaseUnits();

            if (parseFloat(purchaseUnits.amount.value) < FREE_SHIPING_PRICE 
                && parseFloat(purchaseUnits.amount.breakdown.shipping.value) != INTERNATION_SHIPPING_PRICE) {
                    // patch w new shipping
                    console.log('Adding International shipping')
                    const puNewShipping = getPurchaseUnits(false, false, INTERNATION_SHIPPING_PRICE);
                    return actions.order.patch([
                        {
                            op: 'replace',
                            path: '/purchase_units/@reference_id==\'default\'/amount',
                            value: {
                                currency_code: puNewShipping.amount.currency_code,
                                value: puNewShipping.amount.value,
                                breakdown: puNewShipping.amount.breakdown
                            }
                        }
                    ]);
            }
        }

        // Special Canada Case to add shipping and duties
        if (data.shipping_address.country_code === 'CA') {
            const puNewShippingAndTax = getPurchaseUnits(false, true, INTERNATION_SHIPPING_PRICE);
            
            return actions.order.patch([
                {
                    op: 'replace',
                    path: '/purchase_units/@reference_id==\'default\'/amount',
                    value: {
                        currency_code: puNewShippingAndTax.amount.currency_code,
                        value: puNewShippingAndTax.amount.value,
                        breakdown: puNewShippingAndTax.amount.breakdown
                    }
                }
            ]);
        }

        // Add Tax for MD TODO:
        if (data.shipping_address.state === 'MD') {
            const puNewTax = getPurchaseUnits(true);

            return actions.order.patch([
                {
                    op: 'replace',
                    path: '/purchase_units/@reference_id==\'default\'/amount',
                    value: {
                        currency_code: puNewTax.amount.currency_code,
                        value: puNewTax.amount.value,
                        breakdown: puNewTax.amount.breakdown
                    }
                }
            ]);
        }
        
        return actions.resolve();
    }

    const onPaymentReady = () => {
        return console.log('paypal buttons now ready')
    }

    const onPaymentClick = () => {
        return console.log('you clicked the payment button')
    }

    const onPaymentSuccess = (details) => {
        console.log(details)
        emptyCart();
        return navigate("/checkout-success")
    }

    const paypalAmount = (num) => parseFloat(num).toFixed(2)

    const getPurchaseUnits = (applyMDTax = false, applyCanadaTax = false, adjustShipping = undefined) => {
        const currencyCode = 'USD';
        const tax_total = applyMDTax ? (cartTotal * 0.06) : (applyCanadaTax ? CANADA_DUTY_FEE : 0)
        const shippingAmount = adjustShipping == undefined ? shippingTotal : adjustShipping

        const total = cartTotal + shippingAmount + tax_total
        const item_total = cartTotal
        
        const paypalItems = []

        // Format items for paypal
        items.forEach((item) => {
            const { name, quantity, price, hardness } = item
            const obj = {
                name: (hardness) ? `${name} - (${hardness})` : name, // workaround until paypal fixes issue
                unit_amount: {value: (''+price), currency_code: currencyCode},
                quantity: (''+quantity),
            }

            if (hardness) {
                obj.description = `Hardness ${hardness}`
            }
            
            paypalItems.push(obj)
        })
        
        return {
            amount: {
                value: paypalAmount(total),
                currency_code: currencyCode,
                breakdown: { // note breakdown must be accurate of 422 error
                    item_total: {value: paypalAmount(item_total), currency_code: currencyCode},
                    shipping: {value: paypalAmount(shippingAmount), currency_code: currencyCode},
                    tax_total: {value: paypalAmount(tax_total), currency_code: currencyCode},
                }
            },
            items: paypalItems,
        };
    }

    if (isEmpty) return null

    return (
        <>
            <PayPalButton
                amount={cartTotal}
                createOrder={createOrder}
                onSuccess={onPaymentSuccess}
                onButtonReady={onPaymentReady}
                onShippingChange={onShippingChange}
                onClick={onPaymentClick}
                options={{
                    clientId: ReedmakerConfig.paypal.clientId
                }}
            />
        </>
    );
}

export default ReedmakerPayment;
